<template>
  <div>
    <!--begin::User-->
    <div>
      <div v-if="PlanMessage? true: false" class="card-header d-block border-0 pt-6 pb-0">
        <div>
          <b-alert show variant="danger">{{ PlanMessage }}</b-alert>
        </div>
      </div>

      <h3 class="card-label">
        {{ $t('users.users_management') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>

      <div class="row">
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('users.first_name') }}<span class="text-danger">*</span></label>
                <input
                    type="text"
                    v-model="data.first_name"
                    class="form-control"
                    :class="validation && validation.first_name ? 'is-invalid' : ''"
                    :placeholder="$t('users.pl_first_name')"/>
                <span
                    v-if="validation && validation.first_name"
                    class="fv-plugins-message-container invalid-feedback">
                  {{ validation.first_name[0] }}
                </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('users.last_name') }}<span class="text-danger">*</span></label>
                <input
                    type="text"
                    class="form-control"
                    :class="
                    validation && validation.last_name ? 'is-invalid' : ''
                  "
                    v-model="data.last_name"
                    :placeholder="$t('users.pl_last_name')"
                />
                <span
                    v-if="validation && validation.last_name"
                    class="fv-plugins-message-container invalid-feedback"
                >
                  {{ validation.last_name[0] }}
                </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('users.user_name') }}<span class="text-danger">*</span></label>
                <div class="input-group">
                  <input
                      type="text"
                      class="form-control"
                      v-model="data.username"
                      :placeholder="$t('users.pl_username')"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text"
                    ><i class="la la-user-shield"></i
                    ></span>
                  </div>
                </div>
                <span
                    v-if="validation && validation.username"
                    class="fv-plugins-message-container invalid-feedback"
                >
                    {{ validation.username[0] }}
                  </span>
              </div>
            </div>
          </div>
          <div class="card card-custom mt-5">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('users.countries') }}</label>
                <div class="input-group">
                  <multiselect
                      :class="validation && validation.country_id ? 'is-invalid' : ''"
                      v-model="country"
                      :placeholder="$t('users.countries')"
                      label="name"
                      track-by="id"
                      :options="countries"
                      :multiple="false"
                      :taggable="false"
                      :show-labels="false"
                      :show-no-options="false"
                      :show-no-results="false"
                      @search-change="getCountries($event)"
                      :internal-search="false"
                  >
                  </multiselect>
                </div>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('users.cities') }}</label>
                <div class="input-group">
                  <multiselect
                      :class="validation && validation.city ? 'is-invalid' : ''"
                      v-model="city"
                      :placeholder="$t('users.cities')"
                      label="name"
                      track-by="id"
                      :options="cities"
                      :multiple="false"
                      :taggable="false"
                      :show-labels="false"
                      :show-no-options="false"
                      :show-no-results="false"
                  >
                  </multiselect>
                </div>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('users.inventory') }}</label>
                <multiselect v-model="inventory"
                             :placeholder="$t('users.inventory')"
                             label="name"
                             track-by="id"
                             :options="inventories"
                             @input="getMultiSelectInventory"
                             :multiple="true"
                             :class="validation && validation.multi_inventories ? 'is-invalid' : ''"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false">
                </multiselect>
                <span v-if="validation && validation.multi_inventories"
                      class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.multi_inventories[0] }}
                                    </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('users.branch') }}</label>
                <multiselect v-model="branch"
                             :placeholder="$t('users.branch')"
                             label="name"
                             track-by="id"
                             :options="branches"
                             @input="getMultiSelectBranch"
                             :multiple="true"
                             :class="validation && validation.multi_branches ? 'is-invalid' : ''"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false">
                </multiselect>
                <span v-if="validation && validation.multi_branches"
                      class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.multi_branches[0] }}
                                    </span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('users.email') }}<span class="text-danger">*</span></label>
                <div class="input-group">
                  <input
                      type="email"
                      class="form-control"
                      :class="validation && validation.email ? 'is-invalid' : ''"
                      v-model="data.email"
                      :placeholder="$t('users.pl_email')"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text"
                    ><i class="la la-envelope"></i
                    ></span>
                  </div>
                  <span
                      v-if="validation && validation.email"
                      class="fv-plugins-message-container invalid-feedback"
                  >
                    {{ validation.email[0] }}
                  </span>
                </div>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('users.password') }}<span class="text-danger">*</span></label>
                <div class="input-group">
                  <input
                      type="password"
                      autocomplete="new-password"
                      class="form-control"
                      :class="
                      validation && validation.password ? 'is-invalid' : ''
                    "
                      v-model="data.password"
                      :placeholder="$t('users.pl_password')"

                  />
                  <div class="input-group-append">
                    <span class="input-group-text"
                    ><i class="la la-lock"></i
                    ></span>
                  </div>
                  <span
                      v-if="validation && validation.password"
                      class="fv-plugins-message-container invalid-feedback"
                  >
                    {{ validation.password[0] }}
                  </span>
                </div>
              </div>
              <div class="col-lg-6 mb-5">
                <label
                >{{ $t('users.confirm_password') }}<span class="text-danger"
                >*</span
                ></label
                >
                <div class="input-group">
                  <input
                      type="password"
                      class="form-control"
                      :class="
                      validation && validation.password_confirmation
                        ? 'is-invalid'
                        : ''"
                      v-model="data.password_confirmation"
                      :placeholder="$t('users.pl_confirm_password')"

                  />
                  <div class="input-group-append">
                    <span class="input-group-text"
                    ><i class="la la-lock"></i
                    ></span>
                  </div>
                  <span
                      v-if="validation && validation.password_confirmation"
                      class="fv-plugins-message-container invalid-feedback"
                  >
                    {{ validation.password_confirmation[0] }}
                  </span>
                </div>
              </div>
              <div class="col-lg-6 mb-5">
                <div>
                  <label>{{ $t('users.roles') }}</label>
                  <multiselect
                      v-model="roles_data"
                      :placeholder="$t('users.roles')"
                      label="name"
                      track-by="id"
                      :options="roles"
                      @input="getRoleById"
                      :multiple="true"
                      :taggable="false"
                      :show-labels="false"
                      :show-no-options="false"
                      :show-no-results="false">
                  </multiselect>
                </div>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('users.status') }}:</label>
                <div class="radio-inline">
                  <label class="radio radio-solid">
                    <!-- <input type="radio" v-model="data.status"/> -->
                    <input
                        name="status"
                        v-model="data.status"
                        type="radio"
                        value="active"
                        :checked="checkedValue == 'active'"
                    />
                    <span></span>
                    {{ $t('users.active') }}
                  </label>
                  <label class="radio radio-solid">
                    <input
                        name="status"
                        v-model="data.status"
                        type="radio"
                        value="inactive"
                        :checked="checkedValue == 'inactive'"
                    />
                    <span></span>
                    {{ $t('users.inactive') }}
                  </label>
                </div>
                <!-- <span class="form-text text-muted">Please select user group</span> -->
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="reset" class="btn btn-primary mr-2" @click="save()">{{ $t('save') }}</button>
            <button type="reset" class="btn btn-secondary">{{ $t('cancel') }}</button>
          </div>
        </div>
      </div>

    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "form-user",
  data() {
    return {
      mainRouteDependency: 'base/dependency',
      data: {
        first_name: "",
        last_name: "",
        username: "",
        email: "",
        password: "",
        password_confirmation: "",
        country_id: "",
        city_id: "",
        status: "active",
        role_id: [],

        multi_inventories: null,
        multi_branches: null,
        // invoice_bonds: [],
      },
      checkedValue: "",

      isEditing: false,
      idEditing: this.$route.params.id ? this.$route.params.id : null,
      countries: [],
      cities: [],
      roles: [],
      validation: null,
      country: null,
      city: null,
      PlanMessage: null,
      roles_data: null,

      inventory: [],
      branch: [],
      inventories: [],
      branches: [],

    };
  },
  watch: {
    // "data.country_id": function (val) {
    //   if (val) {
    //     this.getCities(val).then((cities) => {
    //       this.cities = cities;
    //     });
    //   }
    // },
    "country": function (val) {
      // if(this.data.country_id != val.id) {
      //   this.city = [];
      // }
      if (val && val.id) {
        this.data.country_id = val.id;
        this.getCities(val.code2);
      }
    },
    "city": function (val) {
      if (val) {
        this.data.city_id = val.id;
      }
    },
  },
  methods: {
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      // this.getRoleById();
      ApiService.post("users", {
        ...this.data,
      })
          .then((response) => {
            this.validation = null;
            this.$successAlert(response.data.message);
            this.$router.push({name: 'users.index'});
          })
          .catch((error) => {
            this.validation = error.response ? error.response.data.errors : null;
          });
    },
    getRoleById() {
      // if (typeof this.data.role_id !== 'undefined') {
      let role_ids = [];
      this.roles_data.filter((role) => {
        role_ids.push(role.id);
      });
      this.data.role_id = role_ids;
      // }
    },

    update() {
      // this.getRoleById();
      ApiService.put(`users/${this.$route.params.id}`, {
        ...this.data,
      })
          .then((response) => {
            this.validation = null;
            this.$router.push({name: 'users.index'});
            this.$successAlert(response.data.message);
          })
          .catch((error) => {
            this.validation = error.response ? error.response.data.errors : null;
          });
    },

    async getData() {
      ApiService.get(`users/${this.$route.params.id}`).then((response) => {
        this.isEditing = true;
        this.data = response.data.data;
        this.country = response.data.data.country;
        this.city = response.data.data.city;
        this.roles_data = response.data.data.role_id;
        // this.data.invoice_bonds = response.data.data.invoice_bonds;
        this.getRoleById();

        this.data.multi_inventories = response.data.data.multi_inventories;
        this.inventory = this.inventories.filter((row) => response.data.data.multi_inventories.includes(row.id));

        this.data.multi_branches = response.data.data.multi_branches;
        this.branch = this.branches.filter((row) => response.data.data.multi_branches.includes(row.id));
      });
    },
    getMultiSelectInventory() {
      this.data.multi_inventories = [];
      this.inventory.filter((row) => {
        this.data.multi_inventories.push(row.id);
      });
    },
    getMultiSelectBranch() {
      this.data.multi_branches = [];
      this.branch.filter((row) => {
        this.data.multi_branches.push(row.id);
      });
    },
    getCountries(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get("base/dependency/countries", {params: {filter: filter}}).then((response) => {
          this.countries = response.data.data;
        });
      }
    },
    getRoles() {
      ApiService.get("base/dependency/roles").then((response) => {
        this.roles = response.data.data.map((role) => {
          return {
            id: role.id,
            name: role.name,
          };
        });
      });
    },


    getCities(id) {
      ApiService.get(`base/dependency/cities/${id}`).then((response) => {
        this.cities = response.data.data;
      });
    },

    getPlanMessage() {
      ApiService.get(this.mainRouteDependency + `/check_limits/users`).then((response) => {
        this.PlanMessage = response.data.data;
      });
    },

    async getBranches() {
      await ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
        this.branches = response.data.data;
      });
    },
    async getInventories() {
      await ApiService.get(this.mainRouteDependency + "/inventories").then((response) => {
        this.inventories = response.data.data;
      });
    },


  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.user_Management"), route: '/users/users'}, {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);
    // this.getCountries();
    this.getRoles();

    let promise = this.getInventories();
    let promise2 = this.getBranches();

    if (this.idEditing) {
      Promise.all([promise, promise2]).then(() => {
        this.getData();
      });
    } else {
      this.getPlanMessage();
    }
    this.checkedValue = "active";
  },
};
</script>


